import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { setIsProceedToNextPage } from '../../../store/config';
import { setIsProceedToPrevPage } from '../../../store/config';
import { useAzureAuth } from '../../../hooks/useAzureAuth';
import { useCallback } from 'react';
import { DocumentTypes } from '@urbanx/agx-ui-components';

export const requestGetFileLink = async (
  authToken: string,
  filePath: string
): Promise<string | null> => {
  try {
    const { data: result } = await campaignsApi(authToken).get<string>(
      'GetUploadedFileLink',
      { filePath }
    );

    return result;
  } catch (err: any) {
    console.error(err);
  }

  return null;
};

export const useGetFileLink = () => {
  const [, getAuthToken] = useAzureAuth();

  const getFileLink = useCallback(
    async (filePath: string): Promise<string | null> => {
      try {
        const token = await getAuthToken();

        if (!token) return null;

        return await requestGetFileLink(token, filePath);
      } catch (err: any) {
        console.error(err);
      }

      return null;
    },
    [getAuthToken]
  );

  return getFileLink;
};

export const deleteFile = async (authToken: string, filePath: string) => {
  try {
    const { status } = await campaignsApi(authToken).post('RemoveFile', {
      filePath,
    });
    return status;
  } catch (err: any) {
    console.error(err);
  }
};

export const useUploadFile = () => {
  const dispatch = useAppDispatch();
  const progress = ({ loaded, total }: { loaded: number; total: number }) => {
    if (loaded !== total) {
      dispatch(setIsProceedToNextPage(false));
      dispatch(setIsProceedToPrevPage(false));
    } else {
      dispatch(setIsProceedToNextPage(true));
      dispatch(setIsProceedToPrevPage(true));
    }
  };

  const uploadFile = async (
    authToken: string,
    file: any,
    campaignId: string,
    documentType: DocumentTypes,
    onUploadProgressChanged: ((uploadProgress: any) => void) | null = null,
    abortSignal: AbortSignal | undefined = undefined
  ) => {
    try {
      const { data: result } = await campaignsApi(authToken).post(
        'AddOrUpdateDocument',
        file,
        {
          CampaignId: campaignId,
          FileType: documentType,
        },
        abortSignal,
        uploadProgress => {
          progress(uploadProgress);
          if (onUploadProgressChanged != null) {
            onUploadProgressChanged(uploadProgress);
          }
        }
      );
      return result;
    } catch (err: any) {
      console.error(err);
      dispatch(setIsProceedToNextPage(true));
      dispatch(setIsProceedToPrevPage(true));
    }
  };
  return uploadFile;
};

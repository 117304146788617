import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import {
  AgxRow,
  AgxColumn,
  Images,
  AgxBodyText,
  AgxCaption,
  AgxColours,
  AgxSlideUpModal,
  AgxButton,
  AgxDivider,
  AgxTextInput,
  AgxLabel,
  AgxCountdown,
  FormType,
  AgxEdit2Outline,
  EnvelopeSubmissionRecipient,
  EnvelopeSubmissionStatus,
  EnvelopeSubmissionType,
} from '@urbanx/agx-ui-components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { ReactComponent as GreenCheck } from '../../Assets/checkmark-circle-2.svg';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { DigitalSignatureClientDetails } from 'Api/Campaigns/Types/types';
import { setAndShowSuccessToast, setAndShowErrorToast } from 'store/config';
import { resendEnvelope } from 'Api/DocuSign/docusignApi';
import { sendForm } from 'Api/Campaigns/campaignsApi';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { useSelectedCampaign } from 'hooks/useSelectedCampaign';
import { useSelectedContract } from 'hooks/useSelectedContract';
import './Signatory.scss';

interface SignatoryProps {
  signatory: EnvelopeSubmissionRecipient;
  envelopeSubmissionType?: EnvelopeSubmissionType | null;
  envelopeId: string;
  declinedStatus: boolean;
}

export const Signatory = ({
  signatory,
  envelopeSubmissionType,
  envelopeId,
  declinedStatus,
}: SignatoryProps) => {
  const [, getAuthToken] = useAzureAuth();
  const {
    name,
    emailAddress,
    status,
    sentDateTime,
    deliveredDateTime: viewedAt,
    signedDateTime: signedAt,
    recipientId,
  } = signatory;

  const dispatch = useAppDispatch();
  const selectedCampaign = useSelectedCampaign();
  const selectedContract = useSelectedContract();
  const [isDisabled, setIsDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0); // Remaining time in seconds
  const [isActive, setIsActive] = useState(false);
  const [sending, setSending] = useState(false);
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const isMobile = ScreenSize() === Breakpoints.Mobile;
  const [recipientDetails, setRecipientDetails] =
    useState<DigitalSignatureClientDetails>({
      emailOverrides: null,
      name: signatory.name,
      preferredName: signatory.preferredName,
      stakeholderType: signatory.stakeholderType,
    });

  const sentAtMoment = moment(sentDateTime);
  const viewedAtMoment = moment(viewedAt);
  const signedAtMoment = moment(signedAt);

  const moreOptionsRef = useRef(null);
  useOutsideAlerter(moreOptionsRef, onTriggerOutside => {
    if (onTriggerOutside === 'outside') setIsActive(false);
  });

  const submissionStatusToIcon = (
    submissionStatus: EnvelopeSubmissionStatus
  ) => {
    switch (submissionStatus) {
      case EnvelopeSubmissionStatus.Created:
        return envelopeSubmissionType === EnvelopeSubmissionType.DocuSign ? (
          <Images.PaperPlaneOutline />
        ) : (
          <Images.Clock />
        );
      case EnvelopeSubmissionStatus.Sent:
        return envelopeSubmissionType === EnvelopeSubmissionType.DocuSign ? (
          <Images.Clock />
        ) : (
          <GreenCheck />
        );
      case EnvelopeSubmissionStatus.Opened:
        return <Images.Clock />;
      case EnvelopeSubmissionStatus.Signed:
      case EnvelopeSubmissionStatus.Complete:
        return <GreenCheck />;
      case EnvelopeSubmissionStatus.Declined:
        return <Images.RedMinusCircle />;
      case EnvelopeSubmissionStatus.Bounced:
        return <Images.WarningTriangle />;
      default:
        return <Images.PaperPlaneOutline />;
    }
  };

  const submissionStatusToFriendlyString = (
    submissionStatus: EnvelopeSubmissionStatus
  ) => {
    switch (submissionStatus) {
      case EnvelopeSubmissionStatus.Created:
        return `Sending ${envelopeSubmissionType === EnvelopeSubmissionType.DocuSign ? 'DocuSign' : 'email'}...`;
      case EnvelopeSubmissionStatus.Sent:
        return envelopeSubmissionType === EnvelopeSubmissionType.DocuSign
          ? 'Needs to Sign'
          : `Sent at ${sentAtMoment.format('hh:mm A')} on ${sentAtMoment.format('dddd, MMMM Do YYYY')}`;
      case EnvelopeSubmissionStatus.Opened:
        return `Viewed at ${viewedAtMoment.format(
          'hh:mm A'
        )} on ${viewedAtMoment.format('dddd, MMMM Do YYYY')}`;
      case EnvelopeSubmissionStatus.Signed:
      case EnvelopeSubmissionStatus.Complete:
        return `${envelopeSubmissionType === EnvelopeSubmissionType.DocuSign ? 'Signed' : 'Received'} at ${(envelopeSubmissionType ===
        EnvelopeSubmissionType.DocuSign
          ? signedAtMoment
          : sentAtMoment
        ).format('hh:mm A')} on ${(envelopeSubmissionType ===
        EnvelopeSubmissionType.DocuSign
          ? signedAtMoment
          : sentAtMoment
        ).format('dddd, MMMM Do YYYY')}`;
      case EnvelopeSubmissionStatus.Declined:
        return 'Declined';
      case EnvelopeSubmissionStatus.Bounced:
        return 'Email Bounced';
      default:
        return '';
    }
  };

  const handleResend = async () => {
    if (envelopeSubmissionType === EnvelopeSubmissionType.Email) {
      setShowResendEmailModal(true);
      return;
    }

    const authToken = await getAuthToken();

    if (!authToken) return;

    setIsActive(false);
    setSending(true);

    try {
      const successMessage = 'Envelope resent successfully!';
      const data = await resendEnvelope(authToken, envelopeId, recipientId);
      if (data) {
        dispatch(setAndShowSuccessToast(successMessage));
        setSending(false);
      }
    } catch (err: any) {
      console.error(err);
      setSending(false);
      dispatch(setAndShowErrorToast(err.message));
    }
  };

  const sendEmail = async () => {
    if (!getAuthToken || !selectedCampaign?.campaignId) return;

    try {
      const authToken = await getAuthToken();

      if (!authToken) return;

      await sendForm(authToken, {
        campaignId: selectedCampaign?.campaignId,
        formId: selectedContract?.inProgressForm?.formId || '',
        formType: selectedContract?.inProgressForm?.formType as FormType,
        state: selectedCampaign.state,
        digitalSignatureSubmission: {
          recipientDetails: recipientDetails,
          sendVendorsNow: false,
          resendEnvelope: true,
        },
      });
      setShowResendEmailModal(false);
      setIsDisabled(true);
      setTimeLeft(5 * 60);
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleCountdownComplete = () => {
    setIsDisabled(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isDisabled && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0 && isDisabled) {
      handleCountdownComplete();
    }

    return () => clearInterval(timer);
  }, [isDisabled, timeLeft]);

  const resendEmailDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'resendModalStyle'}>
      <AgxEdit2Outline width={35} height={35} />
      <AgxLabel large>Resend Sales Advice</AgxLabel>
      <AgxColumn fill largeGap>
        <AgxBodyText small>
          Confirm email for{' '}
          <AgxBodyText extraClasses="strong">{name}</AgxBodyText>
        </AgxBodyText>
        <AgxTextInput
          id="recipientEmail"
          label="Email address"
          email
          noOptionalLabel
          onInputValueChange={({ value }) => {
            setRecipientDetails({
              ...recipientDetails,
              emailOverrides: {
                [recipientId]: value,
              },
            });
          }}
          stretch
          parentControlValue
        />
      </AgxColumn>
      <AgxDivider expanded />
      <AgxColumn fill largeGap>
        <AgxButton
          primary
          large
          text="Resend"
          dataTestId="agx-docuSignSendForDocuSigning"
          onClick={sendEmail}
        />
      </AgxColumn>
    </AgxColumn>
  );

  return (
    <>
      <div className="signatory">
        <div className="signatoryIconWrapper">
          {submissionStatusToIcon(status)}
        </div>
        <AgxColumn extraClasses="signatoryContentWrapper">
          <AgxBodyText medium extraClasses="signatoryName">
            {name}
          </AgxBodyText>
          <AgxCaption
            colour={AgxColours.NEUTRAL_GREY_800}
            extraClasses="signatoryDetails"
          >
            {emailAddress}
          </AgxCaption>
          <AgxCaption
            colour={AgxColours.NEUTRAL_GREY_800}
            extraClasses="signatoryDetails"
          >
            {submissionStatusToFriendlyString(status)}
          </AgxCaption>
        </AgxColumn>
        {((!signedAt &&
          sentDateTime &&
          envelopeSubmissionType === EnvelopeSubmissionType.DocuSign &&
          (status === EnvelopeSubmissionStatus.Sent ||
            status === EnvelopeSubmissionStatus.Opened) &&
          !declinedStatus) ||
          envelopeSubmissionType === EnvelopeSubmissionType.Email) && (
          <AgxRow
            end
            onClick={e => setIsActive(!isActive)}
            extraClasses="moreOptionsButton"
          >
            <Images.More />
          </AgxRow>
        )}
        {isActive &&
          ((!signedAt &&
            sentDateTime &&
            (status === EnvelopeSubmissionStatus.Sent ||
              status === EnvelopeSubmissionStatus.Opened) &&
            !declinedStatus) ||
            envelopeSubmissionType === EnvelopeSubmissionType.Email) && (
            <div
              className={`moreOptionsContent ${sending ? 'disabled-div' : ''}`}
              ref={moreOptionsRef}
            >
              {isDisabled && status !== EnvelopeSubmissionStatus.Bounced ? (
                <AgxCountdown timeLeft={timeLeft} text="Resend in " />
              ) : (
                <div onClick={handleResend}>
                  {sending ? 'Sending...' : 'Resend'}
                </div>
              )}
            </div>
          )}
      </div>
      {showResendEmailModal && (
        <AgxSlideUpModal
          closeButton
          content={resendEmailDialogBody}
          desktop={!isMobile}
          onClose={() => setShowResendEmailModal(false)}
        />
      )}
    </>
  );
};

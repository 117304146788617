import React, { JSX } from 'react';
import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxLabel,
  AgxRow,
  Breakpoints,
  Company,
  Images,
  LandAreaType,
  useWindowSize,
} from '@urbanx/agx-ui-components';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import placeHolderImage from 'assets/images/placeholder-property.png';
import { AllPropertyDetails } from 'Api/RpData/Types/types';
import { Tabs } from '../../PropertySearch';
import './PropertyImageCard.scss';
import clsx from 'clsx';

interface PropertyCardPart {
  dataTestId?: string;
  name: string;
  icon?: JSX.Element;
  detail: string | number;
}

interface PropertyImageCardProps {
  currentTab: Tabs;
  allPropertyDetails: AllPropertyDetails;
  onClick: (details: AllPropertyDetails) => void;
}

export const PropertyImageCard: React.FC<PropertyImageCardProps> = ({
  allPropertyDetails,
  onClick,
}) => {
  const windowSize = useWindowSize();
  const isDesktopSize = windowSize === Breakpoints.Desktop;

  if (!allPropertyDetails) return null;

  const {
    propertyDetails,
    propertyImageUrls,
    hasPool,
    matchedAddress: address,
    vendors,
  } = allPropertyDetails;
  const primaryParcel = propertyDetails.parcels?.find(p => p.isPrimaryPlan);

  const displayUnits = {
    [LandAreaType.SquareMetres]: 'm²',
    [LandAreaType.Hectares]: 'ha',
    [LandAreaType.Acres]: 'acre',
  };

  // Grouping the property specifications
  const propertySpecs: PropertyCardPart[] = [];

  if (propertyDetails.numberOfBeds != null) {
    propertySpecs.push({
      name: 'Bed',
      icon: <Images.Bed />,
      detail: propertyDetails.numberOfBeds,
    });
  }

  if (propertyDetails.numberOfBathrooms != null) {
    propertySpecs.push({
      name: 'Bath',
      icon: <Images.Bath />,
      detail: propertyDetails.numberOfBathrooms,
    });
  }

  if (propertyDetails.numberOfCarSpaces != null) {
    propertySpecs.push({
      name: 'Car',
      icon: <Images.Car />,
      detail: propertyDetails.numberOfCarSpaces,
    });
  }

  const { landAreaDetails } = propertyDetails;

  if (landAreaDetails?.totalLandArea && landAreaDetails?.totalLandAreaType) {
    const displayUnit =
      displayUnits[landAreaDetails.totalLandAreaType] ||
      displayUnits[LandAreaType.SquareMetres];

    propertySpecs.push({
      name: 'Size',
      icon: <Images.TotalLandArea />,
      detail: `${landAreaDetails.totalLandArea} ${displayUnit}`,
    });
  }

  if (landAreaDetails?.internalArea) {
    propertySpecs.push({
      name: 'Internal',
      icon: <Images.InternalArea />,
      detail: `${landAreaDetails.internalArea} ${displayUnits[LandAreaType.SquareMetres]}`,
    });
  }

  if (hasPool != null) {
    propertySpecs.push({
      name: 'Pool',
      icon: <Images.Pool />,
      detail: hasPool ? 'Yes' : 'No',
    });
  }

  const ownerNames = vendors
    ?.map(vendor => {
      if (vendor.company != null) {
        const { company } = vendor ?? {};
        const companyEntity = company as Company & { name: string };

        return companyEntity.name;
      } else if (vendor.individual != null) {
        const { individual } = vendor ?? {};
        const { firstName, lastName, middleName } = individual?.name ?? {};

        return [firstName, middleName, lastName]
          .filter(namePart => (namePart && namePart?.length > 0) ?? false)
          .join(' ');
      }

      return null;
    })
    .filter(vendor => vendor != null);

  const propertyNumbers: PropertyCardPart[] = [];

  if (propertyDetails.propertyType) {
    propertyNumbers.push({
      dataTestId: 'agx-propertyType',
      name: 'Property Type',
      detail: propertyDetails.propertyType,
    });
  }

  if (primaryParcel?.lotNumber) {
    propertyNumbers.push({
      dataTestId: 'agx-lotNumber',
      name: 'Lot',
      detail: primaryParcel?.lotNumber,
    });
  }

  if (primaryParcel?.planType && primaryParcel?.planNumber) {
    propertyNumbers.push({
      dataTestId: 'agx-planNumber',
      name: 'Plan',
      detail: `${primaryParcel?.planType}/${primaryParcel?.planNumber}`,
    });
  }

  const propertyOwners: PropertyCardPart[] = [];

  if (ownerNames.length > 0) {
    ownerNames.map((owner, i) => {
      propertyOwners.push({
        dataTestId: `agx-ownerName-${i}`,
        name: 'Owner',
        detail: owner,
      });
    });

    // Create the property information sections
    const propertySpecsSections = (
      <AgxRow
        centered
        wrap={!isDesktopSize}
        extraClasses="mappedDetailsRowSpacing propertySpecs"
      >
        {propertySpecs.map((section, i) => (
          <AgxRow
            key={section.name + i}
            centered
            extraClasses="mappedDetailsItem"
          >
            <AgxLabel small extraClasses="propertyImageLabel">
              {section.icon ? section.icon : section.name}
            </AgxLabel>
            <AgxBodyText small extraClasses="propertyImageDetail">
              {section.detail}
            </AgxBodyText>
          </AgxRow>
        ))}
      </AgxRow>
    );

    const propertyNumbersSection = (
      <AgxRow centered extraClasses="mappedDetailsRowSpacing propertyNumbers">
        {propertyNumbers.map((section, i) => (
          <AgxColumn
            key={section.name + i}
            extraClasses="propertyItem propertyNumber"
          >
            <AgxLabel small extraClasses="propertyImageLabel">
              {section.name}
            </AgxLabel>
            <AgxBodyText
              small
              extraClasses="propertyImageDetail"
              dataTestId={section.dataTestId}
            >
              {section.detail}
            </AgxBodyText>
          </AgxColumn>
        ))}
      </AgxRow>
    );

    const propertyOwnersSection = (
      <>
        <AgxRow centered>
          <AgxLabel small extraClasses="propertyImageLabel">
            Owner(s)
          </AgxLabel>
        </AgxRow>
        <AgxRow extraClasses="mappedDetailsRowSpacing propertyOwners">
          {propertyOwners.map((owner, i) => (
            <AgxColumn
              key={owner.name + i}
              extraClasses="propertyItem propertyOwner"
            >
              <AgxBodyText small dataTestId={owner.dataTestId}>
                {owner.detail}
              </AgxBodyText>
            </AgxColumn>
          ))}
        </AgxRow>
      </>
    );

    const [addressLine1, addressLine2] = cleanTwoLineAddress(address);

    return isDesktopSize ? (
      <AgxRow
        extraClasses="propertyCard"
        onClick={() => onClick(allPropertyDetails)}
      >
        <div
          style={{
            backgroundImage: `url("${
              propertyImageUrls?.large ?? placeHolderImage
            }")`,
          }}
          className="propertyCard-Image"
        ></div>
        <AgxColumn fill>
          <AgxRow extraClasses="propertyCard-Details">
            <AgxColumn extraClasses="propertyCard-Title">
              <AgxHeader size={4}>{addressLine1},</AgxHeader>
              <AgxHeader size={4}>{addressLine2}</AgxHeader>
            </AgxColumn>
            <AgxColumn>
              <AgxButton
                id="confirmation-yesContinue"
                dataTestId={`testid-propertySearchYesBtn`}
                text="Continue"
                onClick={() =>
                  !isDesktopSize ? onClick(allPropertyDetails) : undefined
                }
                extraClasses="propertyConfirmBtn"
                hollow
                shrinkOnLargerDevices
                medium
              />
            </AgxColumn>
          </AgxRow>
          {propertySpecsSections}
          {propertyNumbersSection}
          {propertyOwnersSection}
        </AgxColumn>
      </AgxRow>
    ) : (
      <AgxColumn extraClasses="propertyCard">
        <div
          style={{
            backgroundImage: `url("${
              propertyImageUrls?.large ?? placeHolderImage
            }")`,
          }}
          className="propertyCard-Image"
        ></div>
        <AgxColumn fill>
          <AgxRow extraClasses="propertyCard-Details">
            <AgxColumn extraClasses="propertyCard-Title">
              <AgxHeader size={4}>{addressLine1},</AgxHeader>
              <AgxHeader size={4}>{addressLine2}</AgxHeader>
            </AgxColumn>
          </AgxRow>
          {propertySpecsSections}
          {propertyNumbersSection}
          {propertyOwnersSection}
        </AgxColumn>
        <AgxColumn fill>
          <AgxButton
            dataTestId="testid-propertySearchYesBtn"
            text="Continue"
            extraClasses="propertyConfirmBtn"
            onClick={() => onClick(allPropertyDetails)}
            hollow
            medium
          />
        </AgxColumn>
      </AgxColumn>
    );
  }
};
